import { connect } from "react-redux"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import React from "react"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"

import AdminLayout from "./layout/Admin/index"
import AuthLayout from "./layout/Auth/index"
import Candidate from "./layout/Candidate/index"
import Recruiter from "./layout/Recruiter/index"
import RouteGuard from "./RouterGuard/index"
import IdleTimeoutModal from "./components/Modals/IdleTimeoutModal"
import { isAdmin, isCandidate, isRecruiter } from "./utils/helper"
import ReactGA from "react-ga"
import TagManager from "react-gtm-module"
import * as Sentry from "@sentry/browser"

// ReactGA.pageview(window.location.pathname + window.location.search);
const CROSSBORDER_CANARY_MEASUREMENT_ID =
  process.env.REACT_APP_CROSSBORDER_CANARY_MEASUREMENT_ID
const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID
}

TagManager.initialize(tagManagerArgs)

ReactGA.initialize(CROSSBORDER_CANARY_MEASUREMENT_ID)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_FRONTEND_DSN,
  integrations: [],
  maskAllText: false,
  blockAllMedia: false
})

function App(props) {
  const { accessToken, userType } = props
  const history = useHistory()
  const { location } = history
  const currentLocation = `${location?.pathname}`
  const currentSearch = `${location?.search ?? ""}`
  const locationState = location?.state || {}

  return (
    <div className="theme-light">
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            maxWidth: "500px"
          },
          success: {
            duration: 3000,
            icon: <CheckCircleIcon />,
            style: {
              border: "1px solid #1D8EE6",
              padding: "20px",
              color: "#1D8EE6",
              fontSize: "20px"
            }
          },
          error: {
            duration: 5000,
            icon: <CancelIcon />,
            style: {
              border: "1px solid #B70000",
              padding: "20px",
              color: "#B70000",
              fontSize: "20px"
            }
          }
        }}
      />
      {accessToken && userType && <IdleTimeoutModal />}
      <Switch>
        <RouteGuard
          path="/auth"
          component={props => <AuthLayout {...props} />}
        />
        {isCandidate(userType) && (
          <RouteGuard
            path="/candidate"
            component={props => <Candidate {...props} />}
            isProtected
          />
        )}
        {isRecruiter(userType) && (
          <RouteGuard
            path="/recruiter"
            component={props => <Recruiter {...props} />}
            location={location}
            isProtected
          />
        )}
        {isAdmin(userType) && (
          <Route path="/admin">
            {isAdmin(userType) && (
              <RouteGuard
                component={props => <AdminLayout {...props} />}
                location={location}
                isProtected
              />
            )}
          </Route>
        )}
        <Route exact path="/">
          <Redirect
            to={{
              pathname: "/auth/login",
              search: currentSearch,
              state: { ...locationState, referrer: currentLocation }
            }}
          />
        </Route>
        <Route path="*">
          <Redirect
            to={{
              pathname: "/auth/login",
              search: currentSearch,
              state: { ...locationState, referrer: currentLocation }
            }}
          />
        </Route>
      </Switch>
    </div>
  )
}

const mapStateToProps = state => ({
  accessToken: state.login.accessToken,
  userType: state.login.userType
})

export default connect(mapStateToProps, null)(App)
